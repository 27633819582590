import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../types/auth';

export interface AuthState {
	data: {
		user: User | null;
		isAuthenticated: boolean;
		isVerified: boolean;
		mfaToken?: string | null;
		oobCode?: string | null;
		accessToken?: string;
		idToken?: string;
	};
	loading: {
		login: boolean;
		verifyLogin: boolean;
		refreshToken: boolean;
	};
	error: {
		login: string | null;
		verifyLogin: string | null;
	};
}

const initialState: AuthState = {
	data: {
		user: null,
		isAuthenticated: false,
		isVerified: false,
	},
	loading: {
		login: false,
		verifyLogin: false,
		refreshToken: false,
	},
	error: {
		login: null,
		verifyLogin: null,
	},
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginRequest: (
			state,
			_action: PayloadAction<{
				email: string;
				password: string;
				signInOption: string;
			}>
		) => {
			state.loading.login = true;
			state.error.login = null;
		},
		loginSuccess: (
			state,
			action: PayloadAction<{
				mfaToken: string;
				oobCode: string;
			}>
		) => {
			state.loading.login = false;
			state.data.isAuthenticated = true;
			state.data.mfaToken = action.payload.mfaToken;
			state.data.oobCode = action.payload.oobCode;
		},
		loginFailure: (state, action: PayloadAction<string>) => {
			state.loading.login = false;
			state.error.login = action.payload;
		},
		//
		verifyLoginRequest: (
			state,
			_action: PayloadAction<{
				code: string[];
				mfaToken: string;
				oobCode?: string | null;
			}>
		) => {
			state.loading.verifyLogin = true;
			state.error.verifyLogin = null;
		},
		verificationSuccess: (
			state,
			action: PayloadAction<{
				accessToken: string;
				idToken: string;
			}>
		) => {
			//TODO: query current user and save to store
			state.loading.verifyLogin = false;
			state.data.isVerified = true;
			state.data.accessToken = action.payload.accessToken;
			state.data.idToken = action.payload.idToken;
			state.data.mfaToken = null;
			state.data.oobCode = null;
		},
		verificationFailure: (state, action: PayloadAction<string>) => {
			state.loading.verifyLogin = false;
			state.error.verifyLogin = action.payload;
		},
		refreshTokenRequest: (state) => {
			state.loading.refreshToken = true;
		},
		refreshTokenSuccess: (
			state,
			action: PayloadAction<{
				accessToken: string;
				idToken?: string;
			}>
		) => {
			state.data.accessToken = action.payload.accessToken;
			state.data.idToken = action.payload.idToken;
			state.loading.refreshToken = false;
			state.data.isAuthenticated = true;
			state.data.isVerified = true;
		},
		refreshTokenFailure: (state) => {
			state.data.user = null;
			state.data.isAuthenticated = false;
			state.data.isVerified = false;
			state.loading.refreshToken = false;
		},
		logout: (state) => {
			state.data.user = null;
			state.data.isAuthenticated = false;
			state.data.isVerified = false;
		},
		clearError: (state) => {
			state.error.login = '';
			state.error.verifyLogin = '';
		},
	},
});

export const authActions = {
	loginRequest: authSlice.actions.loginRequest,
	loginSuccess: authSlice.actions.loginSuccess,
	loginFailure: authSlice.actions.loginFailure,
	refreshTokenRequest: authSlice.actions.refreshTokenRequest,
	refreshTokenSuccess: authSlice.actions.refreshTokenSuccess,
	refreshTokenFailure: authSlice.actions.refreshTokenFailure,
	//
	verificationRequest: authSlice.actions.verifyLoginRequest,
	verificationSuccess: authSlice.actions.verificationSuccess,
	verificationFailure: authSlice.actions.verificationFailure,
	logout: authSlice.actions.logout,
	clearError: authSlice.actions.clearError,
};

// Reducer
export default authSlice.reducer;
