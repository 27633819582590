import {
	_monitoredWallets,
	_transactionsInRisk,
	_transactionsStatus,
	_volume,
} from '../../_mock';
import { transactionResponseMapper } from '../../mapper';
import { transactionApi } from '../../services/axios';
import { ResponseData, ResponsePaginated } from '../../types/common';
import { ResponseTransaction } from '../../types/transaction';

export const getTransactionsApi = async (
	params: {
		pn?: number;
		ps?: number;
	} = {
		pn: 1,
		ps: 10,
	}
) => {
	const queryParams = {
		pn: `${params?.pn}`,
		ps: `${params?.ps}`,
	};
	const queryString = new URLSearchParams(queryParams).toString();
	try {
		const response = await transactionApi.get<
			ResponseData<ResponsePaginated<ResponseTransaction>>
		>(`/v1/transaction?${queryString}`, {});
		const formattedList = response.data.data.data.map((transaction) =>
			transactionResponseMapper(transaction)
		);
		const data = { ...response.data.data, data: formattedList };
		return data;
	} catch (error) {
		console.log('error', error);
	}
};

export const getTransactionsStatsApi = () => {
	return new Promise((resolve) => {
		const mockData = {
			byStatus: _transactionsStatus,
			byRiskLevel: _transactionsInRisk,
		};

		setTimeout(() => {
			resolve({ data: mockData });
		}, 2000);
	});
};

export const getMonitoredWalletsApi = () => {
	return new Promise((resolve) => {
		const mockData = _monitoredWallets;

		setTimeout(() => {
			resolve({ data: mockData });
		}, 2000);
	});
};

export const getVolumesApi = () => {
	return new Promise((resolve) => {
		const mockData = _volume;

		setTimeout(() => {
			resolve({ data: mockData });
		}, 2000);
	});
};
