import HorizontalBarChart from '../../organisms/Chart/HorizontalBarChart';
import BaseCard from '../../organisms/BaseCard';

const Volume: React.FC<{ data: any[] }> = ({ data }) => {
	return (
		<BaseCard
			title={`$250,002,110`}
			subtitle="Total Volume"
			cardContentStyle="h-[240px]"
			cardContentWrapperStyle="h-[240px]"
		>
			<div className="grid grid-cols-1 gap-4 h-full">
				{data && <HorizontalBarChart data={data} />}
			</div>
		</BaseCard>
	);
};

export default Volume;
