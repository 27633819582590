import BaseCard from '../../organisms/BaseCard';
import { getChartBgColor } from '../../../utils/getChartColor';
import TransactionStatusChart from '../../organisms/Chart/TransactionStatusChart';

const TransactionsInRisk: React.FC<{ data: any[] }> = ({ data }) => {
	const updatedRiskData = data.map((item) => {
		const { color } = getChartBgColor(item?.code);
		return { ...item, color };
	});

	return (
		<BaseCard
			title="Transaction Status & Distribution"
			subtitle={`Total transactions: ${38}`}
			cardContentStyle="h-[240px]"
			cardContentWrapperStyle="h-[240px]"
		>
			{data && <TransactionStatusChart data={updatedRiskData} />}
		</BaseCard>
	);
};

export default TransactionsInRisk;
