import ResponsiveIcon from '../atoms/Icon';
import { Button } from '../atoms/Button';
import { iconMap } from '../../theme/Icons';

export enum Range {
	today = '24h',
	week = 'week',
	month = 'month',
	custom = 'custom',
}

const filterOptions = {
	[Range.today]: 'Last 24h',
	[Range.week]: 'This Week',
	[Range.month]: 'This Month',
	[Range.custom]: 'Custom',
};

type DateFilterPanelType = {
	selectedRange: Range;
	onFilterChange: (range: Range) => void;
};

const DateFilterPanel: React.FC<DateFilterPanelType> = ({
	selectedRange,
	onFilterChange,
}) => {
	return (
		<div className="flex flex-wrap gap-2.5">
			{Object.entries(filterOptions).map(([range, title]) => {
				return (
					<Button
						key={range}
						onClick={() => onFilterChange(range as Range)}
						variant={selectedRange === range ? 'default' : 'outline'}
						className="border-primary-100 text-sm"
					>
						{title}
						{range === Range.custom && (
							<ResponsiveIcon icon={iconMap.simpleCalendarIcon} />
						)}
					</Button>
				);
			})}
		</div>
	);
};

export default DateFilterPanel;
