import { CellContext, ColumnDef, ColumnMeta } from '@tanstack/react-table';
import { useState } from 'react';
import { _cryptocurrencies } from '../../../_mock';

import { iconMap } from '../../../theme/Icons';
import {
	BackGuarantee,
	Transaction,
	TransactionStatus,
	WalletDataType,
} from '../../../types/transaction';

import { shortenString } from '../../../utils/string';
import { Button } from '../../atoms/Button';
import { Guarantee, GuaranteeProps } from '../../atoms/Guarantee';
import ResponsiveIcon from '../../atoms/Icon';
import { Status, StatusProps } from '../../atoms/Status';

import {
	getDateTimeCell,
	getRiskLevelandFactorsCell,
	getRiskLevelCell,
	getTotalAmountCell,
	WalletCodeCell,
} from '../../organisms/Table/baseConfig';
import {
	FilterOptionsType,
	FilterTypeEnum,
	TypeRangeFilterData,
} from '../../organisms/Table/BaseTable';
import { cn } from '../../../libs/cn';

const StatusCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();
	const status = value.toLocaleLowerCase()?.replace(' ', '');

	const [transactionStatus, setTransactionStatus] = useState(status);

	const handlePause = () => {
		setTransactionStatus(TransactionStatus.paused);
	};

	const handleResend = () => {
		setTransactionStatus(TransactionStatus.transferring);
	};

	return (
		<div className="flex justify-between transition-all w-44">
			{value === TransactionStatus.unknown ? (
				<div className="flex items-center group gap-1">
					<ResponsiveIcon
						icon={iconMap.hourglass}
						className="h-5 w-5 transform transition-transform duration-500 ease-in-out group-hover:rotate-180"
					/>
					<p>Waiting for User Action</p>
				</div>
			) : transactionStatus === TransactionStatus.paused ? (
				<Button
					className="hover:gap-2 bg-grey-table px-3 py-0 rounded-2xl border-transparent text-sm"
					onClick={handleResend}
					icon={<ResponsiveIcon icon={iconMap.refresh} />}
					iconPosition="right"
				>
					Resend
				</Button>
			) : (
				<div
					className={cn(
						'flex gap-1 items-center capitalize',
						transactionStatus === TransactionStatus.transferring &&
							'flex-row-reverse items-center justify-center w-full group-hover:opacity-50'
					)}
				>
					<Status variant={transactionStatus as StatusProps['variant']} />
					{transactionStatus === TransactionStatus.transferring
						? `${transactionStatus}...`
						: transactionStatus}
				</div>
			)}
			{transactionStatus === TransactionStatus.transferring && (
				<div className="hidden group-hover:block">
					<Button
						className="hover:gap-2 bg-grey-table px-3 py-0 rounded-2xl border-transparent text-sm"
						onClick={handlePause}
					>
						Pause
						<ResponsiveIcon icon={iconMap.pause} />
					</Button>
				</div>
			)}
		</div>
	);
};

const getGuaranteeCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();

	return (
		<div className="space-y-2">
			<div className="flex flex-row">
				<div
					className={cn('flex items-center gap-3 capitalize text-black pr-2')}
				>
					{value || '-'}
					<Guarantee variant={value as GuaranteeProps['variant']} />
				</div>
				{value === BackGuarantee.yes && (
					<div className="text-sm flex gap-1 bg-grey-table px-3 py-0 rounded-2xl items-center">
						<span>Details</span>
						<ResponsiveIcon icon={iconMap.file} className="w-4 h-4" />
					</div>
				)}
			</div>
			{props.row.getIsExpanded() && (
				<Button variant={'outline'} disabled>
					Make a Claim
				</Button>
			)}
		</div>
	);
};

///////////////

export type ColumnType = ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: ColumnStyleType | undefined;
};

export type ColumnStyleType = {
	width?: string | number;
};

export const dashboardColumns: (ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: ColumnStyleType;
})[] = [
	{
		accessorKey: 'dateTime',
		header: 'Sent',
		cell: getDateTimeCell,
		size: 120,
	},
	{
		accessorKey: 'from',
		header: 'From',
		cell: (props) => <WalletCodeCell {...props.getValue<WalletDataType>()} />,
		size: 80,
	},
	{
		accessorKey: 'to.code',
		header: 'To',
		cell: (props) => (
			<WalletCodeCell
				code={props.getValue<string>()}
				className={cn({
					truncate: !props.row.getIsExpanded(),
					'break-words': props.row.getIsExpanded(),
				})}
			/>
		),

		size: 80,
	},
	{
		accessorKey: 'hash',
		header: 'Hash#',
		cell: (props) => {
			const value = props.getValue<string>();
			return <span>{value?.length ? shortenString(value) : '-'}</span>;
		},
		size: 100,
	},
	{
		accessorKey: 'totalAmount',
		header: () => <span className="whitespace-nowrap">Total Amount</span>,
		cell: getTotalAmountCell,
		size: 100,
	},
	{
		accessorKey: 'riskLevel',
		header: () => <span className="block truncate">Risk Level & Factors</span>,
		cell: getRiskLevelandFactorsCell,
		size: 100,
	},
	{
		accessorKey: 'status',
		header: () => <span className="block">Status</span>,
		cell: StatusCell,
		size: 220,
	},
	{
		accessorKey: 'moneyBackGuarantee',
		header: () => <div className="flex-1 truncate">Money Back Guarantee</div>,
		cell: getGuaranteeCell,
		size: 120,
	},
	{
		accessorKey: 'actions',
		header: '',
		size: 20,
		cell: ({ row }) => (
			<div
				className="cursor-pointer h-[52px] flex items-center"
				onClick={() => row.toggleExpanded()}
			>
				<ResponsiveIcon
					icon={iconMap.outlinedArrowDown}
					className={row.getIsExpanded() ? 'rotate-180' : ''}
				/>
				{/* {<RenderExpandedRowContent row={row} />} */}
			</div>
		),
	},
];

export const transactionColumns: (ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: ColumnStyleType;
	meta?: ColumnMeta<
		{
			filter?: FilterOptionsType<TypeRangeFilterData | string[]>;
		},
		unknown
	>;
})[] = [
	{
		accessorKey: 'dateTime',
		header: () => <span className="block">Sent</span>,
		cell: getDateTimeCell,
		size: 140,
		enableSorting: true,
	},
	{
		accessorKey: 'from',
		header: 'From',
		cell: (props) => <WalletCodeCell {...props.getValue<WalletDataType>()} />,
		enableSorting: true,
		size: 100,
		meta: {
			filter: {
				type: FilterTypeEnum.SingleValue,
				data: ['Wallet 1', 'Wallet 2', 'Wallet 3', 'Wallet 4', 'Wallet 5'],
			},
		},
	},
	{
		accessorKey: 'to.code',
		header: 'To',
		cell: (props) => {
			return (
				<WalletCodeCell
					code={props.getValue<string>()}
					className={cn({
						truncate: !props.row.getIsExpanded(),
						'break-words': props.row.getIsExpanded(),
					})}
				/>
			);
		},
		enableSorting: true,
		size: 80,
		meta: {
			filter: {
				type: FilterTypeEnum.SingleValue,
				data: ['Wallet 1', 'Wallet 2', 'Wallet 3', 'Wallet 4', 'Wallet 5'],
			},
		},
	},
	{
		accessorKey: 'hash',
		header: 'Hash#',
		cell: (props) => {
			const value = props.getValue<string>();
			if (!value) {
				return <div className="text-center w-full">-</div>;
			}
			return <span className="underline">{shortenString(value)}</span>;
		},
		size: 100,
		enableSorting: true,
	},
	{
		accessorKey: 'totalAmount',
		header: () => 'Total Amount',
		cell: getTotalAmountCell,
		enableSorting: true,
		size: 120,
		meta: {
			expandableContent: true,
			filter: {
				type: FilterTypeEnum.range,
				data: {
					dropdownItems: _cryptocurrencies.map<string>((item) =>
						item.cryptocurrency.toString()
					),
					range: [0, 100],
				},
			},
		},
	},
	{
		accessorKey: 'riskLevel',
		header: 'Risk Level',
		cell: getRiskLevelCell,
		enableSorting: true,
		size: 120,
	},
	{
		accessorKey: 'riskFactor',
		header: () => <span className="whitespace-nowrap">Risk Factors</span>,
		cell: (props) => {
			const value = props.getValue<string>();
			return (
				<div
					className={cn({
						'w-full text-center': !value,
					})}
				>
					{value || '-'}
				</div>
			);
		},
		size: 120,
		enableSorting: true,
	},
	{
		accessorKey: 'status',
		header: 'Status',
		cell: StatusCell,
		enableSorting: true,
		size: 220,
	},
	{
		accessorKey: 'moneyBackGuarantee',
		header: () => <span>Money Back Guarantee</span>,
		cell: getGuaranteeCell,
		size: 130,
		enableSorting: true,
	},
	{
		accessorKey: 'actions',
		header: '',
		enableSorting: false,
		size: 20,
		cell: ({ row }) => (
			<div
				className="cursor-pointer h-[52px] flex items-center"
				onClick={() => row.toggleExpanded()}
			>
				<ResponsiveIcon
					icon={iconMap.outlinedArrowDown}
					className={row.getIsExpanded() ? 'rotate-180' : ''}
				/>
				{/* {<RenderExpandedRowContent row={row} />} */}
			</div>
		),
	},
];
