import { iconMap } from '../../theme/Icons';
import { Wallet } from '../../types/wallets';
import { Badge } from '../atoms/Badge';
import { Button } from '../atoms/Button';
import { Card, CardContent } from '../atoms/Card';
import ResponsiveIcon from '../atoms/Icon';

interface WalletListItemProps {
	item: Wallet;
	bgColor?: string;
	goToWalletDetails: (id: number) => void;
}

const WalletListItem: React.FC<WalletListItemProps> = ({
	item,
	goToWalletDetails,
}) => {
	return (
		<Card
			key={item.id}
			className="sm:py-2 sm:px-2 flex flex-col lg:flex-row items-center gap-4 hover:shadow-hover-lg hover:cursor-pointer transition-all"
			onClick={() => goToWalletDetails(item.id)}
		>
			<CardContent
				className={`flex flex-row items-center justify-between flex-grow bg-wallets-${item.riskLevel}bg rounded-[10px] p-2`}
			>
				<div className="text-2xl font-roboto-condensed">
					<p>{item.name}</p>
				</div>
				<div className="flex items-center justify-start w-48">
					<div className="flex gap-2 flex-wrap">
						{item?.cryptocurrencies.map((item) => {
							return (
								<div key={item?.code} className="bg-grey-300 p-2 rounded-full">
									<ResponsiveIcon
										icon={iconMap[item.code.toLowerCase()]}
										className="w-5 h-5"
									/>
								</div>
							);
						})}
					</div>
				</div>
				<div className="">
					<p>Wallet address</p>
					<p className="truncate font-bold">{item.address}</p>
				</div>

				<div className="flex items-center gap-1">
					<span>Risk Level</span>
					<Badge className="bg-white" variant={item?.riskLevel}>
						{item?.riskLevel}
					</Badge>
				</div>
			</CardContent>
			<div className="flex items-center justify-start text-grey-600">
				Safely migrate your cryptocurrency to a new wallet address.
			</div>

			<div className="flex gap-1 xl:gap-6 items-center">
				<Button
					disabled={!item.canSend}
					iconPosition="right"
					icon={<ResponsiveIcon icon={iconMap.arrow} className="w-4 h-5" />}
				>
					Migrate
				</Button>
				<div className="flex gap-2">
					<Button
						size={'icon'}
						variant={'icon'}
						icon={<ResponsiveIcon icon={iconMap.edit} className="w-4 h-5" />}
						className="bg-grey-table border-none"
					/>
					<Button
						size={'icon'}
						variant={'icon'}
						icon={
							<ResponsiveIcon
								icon={iconMap.delete}
								color="#7B7B7B"
								className="w-4 h-5"
							/>
						}
						className="bg-grey-table border-none"
					/>
				</div>
			</div>
		</Card>
	);
};

export default WalletListItem;
