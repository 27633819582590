import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	LabelList,
	Legend,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';
import { ContentType } from 'recharts/types/component/DefaultLegendContent';
import { Props } from 'recharts/types/component/Label';
import { LegendWrapper } from './legends/LegendWrapper';

export type TransactionStatusData = {
	name: string;
	value: number;
	color: string[];
	group: string;
	code: string;
};
type TransactionStatusProps = {
	renderLegendContent?: ContentType;
	data: TransactionStatusData[];
};

type TRiskLevel = 'LOW' | 'MEDIUM' | 'HIGH' | 'CRITICAL';

interface IBar {
	key: TRiskLevel;
	fill: string;
	background: string;
}

const bars: IBar[] = [
	{
		key: 'LOW',
		fill: 'url(#baseChartColor)',
		background:
			'linear-gradient(4.16deg, #1CE7DC 3.1%, #1CE7AC 73.87%, #1CE7A2 96.9%)',
	},
	{
		key: 'MEDIUM',
		fill: '#F1C40F',
		background: '#F1C40F',
	},
	{
		key: 'HIGH',
		fill: '#E74C3C',
		background: '#E74C3C',
	},
	{
		key: 'CRITICAL',
		fill: '#C0392B',
		background: '#C0392B',
	},
];

const renderLabel = (props: Props) => {
	const { x = 0, y = 0, width = 0, value } = props;
	const offset = 5;

	return (
		<text
			x={+x + +width + offset}
			y={+y + 10}
			fill="#000"
			textAnchor="start"
			dominantBaseline="middle"
			className="text-sm"
		>
			{value}
		</text>
	);
};

const CustomLegend = () => {
	return (
		<LegendWrapper>
			<span className="text-grey-600 text-sm">Risk level</span>
			{[...bars].reverse().map(({ key, background }) => {
				return (
					<div key={key} className="flex items-center">
						<div
							className="w-[8px] h-[8px] rounded-[50%] mr-1"
							style={{
								background,
							}}
						/>
						<span className="capitalize text-sm">{key.toLowerCase()}</span>
					</div>
				);
			})}
		</LegendWrapper>
	);
};

const TransactionStatusChart = ({ data }: TransactionStatusProps) => {
	const transformedData = data.reduce((acc: any, cur) => {
		const existingGroup = acc.find((item: any) => item.status === cur.group);

		if (existingGroup) {
			existingGroup[cur.code] = cur.value;
		} else {
			acc.push({
				status: cur.group,
				[cur.code]: cur.value,
			});
		}

		return acc;
	}, []);

	const processData = transformedData.map((item: { [x: string]: number }) => {
		const topCode = bars.reverse().find((bar) => item[bar.key] > 0)?.key;

		return {
			...item,
			topCode,
		};
	});

	return (
		<ResponsiveContainer width="100%" className="card-wrapper">
			<BarChart
				data={transformedData}
				margin={{
					top: 15,
					right: 30,
				}}
			>
				<defs>
					<linearGradient id="baseChartColor" x1="0" y1="0" x2="1" y2="0">
						<stop offset="0%" stopColor="#1CE7DC" stopOpacity={0.8} />
						<stop offset="75.5%" stopColor="#1CE7AC" stopOpacity={0.8} />
						<stop offset="100%" stopColor="#1CE7A2" stopOpacity={0.8} />
					</linearGradient>
				</defs>
				<CartesianGrid vertical={false} />
				<XAxis
					className="capitalize text-sm font-bold"
					tick={{ fill: '#000' }}
					dataKey="status"
				/>
				<YAxis type="number" />
				<Legend content={<CustomLegend />} />
				{bars.map(({ key, fill }: IBar) => {
					return (
						<Bar key={key} barSize={18} dataKey={key} stackId="a" fill={fill}>
							{processData.map(
								(entry: { name: any; value: any; topCode: string }) => {
									return (
										<Cell
											key={entry.name + entry.value}
											fill={bars.find((bar) => bar.key === key)?.fill}
											style={{
												...(entry.topCode === key && {
													clipPath:
														'inset(0px 0px 0px 0px round 5px 5px 0px 0px)',
												}),
											}}
										/>
									);
								}
							)}
							<LabelList dataKey={key} content={renderLabel} />
						</Bar>
					);
				})}
			</BarChart>
		</ResponsiveContainer>
	);
};

export default TransactionStatusChart;
