import { PieChartData } from '../components/organisms/Chart/PieChart';

export type TotalAmountType = {
	from: {
		value: number;
		unit: string;
	};
	to: {
		value: number;
		unit: string;
	};
	fee: string;
};

export type WalletDataType = { code: string; wallet: string; walletId: number };

export type Transaction = {
	id: string; // transaction_id
	dateTime: string; //
	from: WalletDataType; //from_address
	proxy: string; //
	to: WalletDataType;
	hash: string;
	totalAmount: TotalAmountType; //
	riskLevel: string; // risk score
	riskFactor: string; //
	status: string; // status
	moneyBackGuarantee: string;
};

export interface ResponseTransaction {
	transaction_id: string;
	coin: string;
	chain: string;
	hash?: string;
	from_address?: string;
	amount: number;
	fiat_amount: number;
	outputs?: ResponseOutputsEntity[] | null;
	fee: string;
	status: string;
	mbg: boolean;
	mbg_policy_id?: string;
	created_at: number;
}
export interface ResponseOutputsEntity {
	address: string;
	amount: number;
	hash: string;
	approved: boolean;
	risk_score: string;
}

export type StatsByStatus = {
	status: string;
	value: number;
};

export type TransactionStats = {
	byRiskLevel: PieChartData[];
	byStatus: PieChartData[];
};

export enum TransactionStatus {
	transferring = 'transferring',
	rejected = 'rejected',
	expired = 'expired',
	completed = 'completed',
	paused = 'paused',
	unknown = 'unknown',
}

export enum BackGuarantee {
	yes = 'yes',
	no = 'no',
}

export enum RiskLvl {
	low = 'low',
	medium = 'medium',
	high = 'high',
	critical = 'critical',
}
