import { Outlet, useLocation } from 'react-router-dom';
import Navbar from '../molecules/navigation/Navbar';
import PageContainer from '../atoms/PageContainer';
import Sidebar from '../molecules/navigation/Sidebar';
import { CustomRouteObject } from '../../routes/router';
import { cn } from '../../libs/cn';

type DashboardLayoutProps = {
	routes: CustomRouteObject[];
};

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ routes }) => {
	const location = useLocation();
	const styles = location?.pathname === '/dashboard' ? 'bg-grey-200' : '';

	return (
		<div className="h-full">
			<div className="h-[80px] md:pl-44 fixed inset-y-0 w-full z-50">
				<Navbar routes={routes} className={styles} />
			</div>

			<div className="hidden md:flex h-full w-44 flex-col fixed inset-y-0 z-50">
				<Sidebar routes={routes} />
			</div>

			<main
				className={cn(
					'md:pl-44 pt-[80px] min-h-screen h-full flex flex-col',
					styles
				)}
			>
				<PageContainer>
					<Outlet />
				</PageContainer>
			</main>
		</div>
	);
};

export default DashboardLayout;
