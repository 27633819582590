import axios from 'axios';
import { API_BASE_TRANSACTION_URL } from '../config';
import { store } from '../store/config.store';
import { refreshTokenWorker } from '../controllers/auth/saga';

export const transactionApi = axios.create({
	baseURL: API_BASE_TRANSACTION_URL,
});

transactionApi.interceptors.request.use(async (config) => {
	const state = store.getState();
	let accessToken = state.auth.data.accessToken;
	if (!accessToken) {
		await store.dispatch(refreshTokenWorker());
		const newState = store.getState();
		accessToken = newState.auth.data.accessToken;
	}
	config.headers['Authorization'] = `Bearer ${accessToken}`;
	return config;
});
