import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { dashboardActions, DashboardState } from './slice';

type TransactionServiceOperators = DashboardState & {
	loadTransactions: (params: { pn?: number; ps?: number }) => void;
	loadTransactionsStats: () => void;
	loadMonitoredWallets: () => void;
	loadVolume: () => void;
};

const useDashboardDataService = (): Readonly<TransactionServiceOperators> => {
	const dispatch = useAppDispatch();
	const dashboardState = useAppSelector((state) => state.transactions);

	const loadTransactions = useCallback(
		(params: { pn?: number; ps?: number }) => {
			dispatch(dashboardActions.fetchTransactionsRequest(params));
		},
		[dispatch]
	);

	const loadTransactionsStats = useCallback(() => {
		dispatch(dashboardActions.fetchStatsRequest());
	}, [dispatch]);

	const loadMonitoredWallets = useCallback(() => {
		dispatch(dashboardActions.fetchMonitoredWalletsRequest());
	}, [dispatch]);

	const loadVolume = useCallback(() => {
		dispatch(dashboardActions.fetchVolumeRequest());
	}, [dispatch]);

	return {
		data: dashboardState.data,
		loading: dashboardState.loading,
		error: dashboardState.error,
		loadTransactions,
		loadTransactionsStats,
		loadMonitoredWallets,
		loadVolume,
	};
};

export default useDashboardDataService;
