import { cn } from '../../../libs/cn';
import {
	CRYPTOCURRENCY,
	cryptocurrencySignMap,
	CURRENCY,
	currencySignMap,
} from '../../../types/currency';
import { Transaction, TransactionStatus } from '../../../types/transaction';
import { formatNumber } from '../../../utils/number';
import { Button } from '../../atoms/Button';
import { Card, CardContent, CardHeader, CardTitle } from '../../atoms/Card';
import { BaseTable } from '../../organisms/Table/BaseTable';
import { Row } from '../../organisms/Table/Table';
import { ColumnType } from './columns';

export const RenderExpandedRowContent: React.FC<{ row: Row<Transaction> }> = ({
	row,
}) => {
	const total = row.original?.totalAmount;

	return (
		<div
			className={cn(
				`bg-grey-200 gap-24 transition-all absolute bottom-4 right-4 left-4 top-20 rounded-lg overflow-hidden`,
				row.getIsExpanded() ? 'flex' : 'hidden'
			)}
		>
			<div className="flex flex-col gap-3 pt-4 pl-4">
				<p className="font-bold text-lg">Fees</p>
				<div className="flex gap-6">
					<div className="flex flex-col gap-3">
						<p>Transaction Fees</p>
						<p>Money Back Guarantee Fees</p>
					</div>

					<div className="flex flex-col gap-3">
						<div className="flex gap-2 items-baseline">
							<p className="flex gap-1">
								<span className="font-extrabold">
									{cryptocurrencySignMap[total?.from.unit as CRYPTOCURRENCY]}
								</span>
								<span>{formatNumber(total?.from.value)}</span>
							</p>

							<p className="flex gap-1 text-grey-600 text-sm">
								<span className="font-extrabold">
									{currencySignMap[total?.to.unit as CURRENCY]}
								</span>
								<span>{formatNumber(total?.to.value)}</span>
							</p>
						</div>

						<div className="flex gap-2 items-baseline">
							<p className="flex gap-1">
								<span className="font-extrabold">
									{cryptocurrencySignMap[total?.from.unit as CRYPTOCURRENCY]}
								</span>
								<span>{formatNumber(total?.from.value)}</span>
							</p>

							<p className="flex gap-1 text-grey-600 text-sm">
								<span className="font-extrabold">
									{currencySignMap[total?.to.unit as CURRENCY]}
								</span>
								<span>{formatNumber(total?.to.value)}</span>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-3 items-start pt-4">
				<p className="font-bold text-lg">Money Back Guarantee</p>
				<Button variant={'outline'}>Make a Claim</Button>
			</div>
		</div>
	);
};

type TransactionTableProps = {
	title?: string;
	subtitle?: string;
	onClick?: () => void;
	btnText?: string;
	isLoading: boolean;
	error: string | null;
	data: Transaction[];
	columns: ColumnType[];
	getRowProps?: (row: any) => React.HTMLAttributes<HTMLTableRowElement>;
	type: 'simple' | 'extended';
};

export function TransactionTable({
	columns,
	data,
	onClick,
	title,
	subtitle,
	btnText,
	isLoading,
	error,
	type,
}: TransactionTableProps) {
	const getRowProps = (row: any) => {
		return {
			className: `group ${row.original.status === TransactionStatus.transferring ? 'hover-opacity-row' : ''}`,
		};
	};

	if (type === 'simple') {
		return (
			<Card className="flex flex-col gap-4 min-h-[500px]">
				<CardHeader className="items-center gap-2 justify-between flex-col sm:flex-row">
					<div>
						{title && (
							<CardTitle className="font-normal font-roboto-condensed text-xl">
								{title}
							</CardTitle>
						)}
						{subtitle && <p>{subtitle}</p>}
					</div>

					{onClick && btnText && (
						<Button onClick={onClick} variant={'outline'} size={'sm'}>
							{btnText}
						</Button>
					)}
				</CardHeader>

				<CardContent>
					<BaseTable
						columns={columns}
						data={data.slice(0, 4)}
						isLoading={isLoading}
						error={error}
						getRowProps={getRowProps}
						headerStyle="bg-grey-50"
					/>
				</CardContent>
			</Card>
		);
	} else {
		return (
			<BaseTable
				columns={columns}
				data={data}
				isLoading={isLoading}
				error={error}
				getRowProps={getRowProps}
			/>
		);
	}
}
