import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent } from '../../atoms/Card';

type CurrenciesWidgetProps = {
	data: {
		cryptocurrency: string | number;
		amount: {
			value: number;
			unit: string | number;
		};
	}[];
};

const CurrenciesWidget: React.FC<CurrenciesWidgetProps> = ({ data }) => {
	const marqueeRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const [marqueeWidth, setMarqueeWidth] = useState<number>(0);
	const [containerWidth, setContainerWidth] = useState<number>(0);

	useEffect(() => {
		if (marqueeRef.current && containerRef.current) {
			const containerWidth = containerRef.current.offsetWidth;
			const contentWidth = marqueeRef.current.scrollWidth;

			setContainerWidth(containerWidth);
			setMarqueeWidth(contentWidth);
		}
	}, [data]);

	return (
		<Card ref={containerRef} className="overflow-hidden p-2 sm:p-2 w-full">
			<div className="marquee-wrapper">
				<CardContent
					ref={marqueeRef}
					className="marquee flex items-center divide-x pl-3"
				>
					{[...data, ...data].map((item, index) => (
						<div
							key={index}
							className="flex gap-5 justify-center items-center px-5 whitespace-nowrap"
						>
							<p>{item?.cryptocurrency}</p>
							<p>${item?.amount?.value.toFixed(2)}</p>
						</div>
					))}
				</CardContent>
			</div>

			<style>{`
        .marquee-wrapper {
          display: flex;
          overflow: hidden;
          position: relative;
        }

        .marquee {
          display: inline-flex;
          animation: marquee ${marqueeWidth / 25}s linear infinite;
        }

        @keyframes marquee {
          0% {
            transform: translateX(${containerWidth}px);
          }
          100% {
            transform: translateX(-${marqueeWidth}px);
          }
        }
      `}</style>
		</Card>
	);
};

export default CurrenciesWidget;
