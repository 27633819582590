import { iconMap } from '../../theme/Icons';
import { Wallet } from '../../types/wallets';
import { Badge } from '../atoms/Badge';
import { Button } from '../atoms/Button';
import { Card, CardContent, CardFooter } from '../atoms/Card';
import ResponsiveIcon from '../atoms/Icon';

interface WalletCardProps {
	item: Wallet;
	bgColor?: string;
	goToWalletDetails: (id: number) => void;
}

const WalletCard: React.FC<WalletCardProps> = ({ item, goToWalletDetails }) => {
	return (
		<Card
			key={item.id}
			className="flex flex-col h-full hover:shadow-hover-lg hover:cursor-pointer transition-all lg:p-3"
			onClick={() => goToWalletDetails(item.id)}
		>
			<CardContent
				className={`gap-2 flex flex-col flex-grow bg-wallets-${item.riskLevel}bg rounded-[10px] p-2`}
			>
				<p className="text-xl">{item?.name}</p>
				<div className="flex gap-2">
					{item?.cryptocurrencies.map((item) => (
						<div key={item?.code} className="bg-white p-2 rounded-full">
							<ResponsiveIcon
								icon={iconMap[item.code.toLowerCase()]}
								className="w-5 h-5"
							/>
						</div>
					))}
				</div>
				<div>
					<p>Wallet Address</p>
					<p className="truncate font-bold">{item?.address}</p>
				</div>
				<div className="flex items-start lg:items-center gap-1 2xl:gap-2 flex-col 2xl:flex-row pt-2">
					<p>Risk Level</p>
					<Badge className="items-center bg-white" variant={item?.riskLevel}>
						{item?.riskLevel}
					</Badge>
				</div>
			</CardContent>

			<CardFooter className="flex justify-between my-6">
				<span className="text-grey-600 text-sm">
					Safely migrate your cryptocurrency to a new wallet address.
				</span>
				<Button
					disabled={!item.canSend}
					iconPosition="right"
					icon={<ResponsiveIcon icon={iconMap.arrow} className="w-4 h-5" />}
				>
					Migrate
				</Button>
			</CardFooter>
			<div className="flex gap-2">
				<Button
					size={'icon'}
					variant={'icon'}
					icon={<ResponsiveIcon icon={iconMap.edit} className="w-4 h-5" />}
					className="bg-grey-table border-none"
				/>
				<Button
					size={'icon'}
					variant={'icon'}
					icon={
						<ResponsiveIcon
							icon={iconMap.delete}
							color="#7B7B7B"
							className="w-4 h-5"
						/>
					}
					className="bg-grey-table border-none"
				/>
			</div>
		</Card>
	);
};

export default WalletCard;
