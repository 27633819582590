import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../libs/cn';
import ResponsiveIcon from './Icon';
import { iconMap } from '../../theme/Icons';

const badgeVariants = cva(
	'inline-flex items-center rounded-full border px-3 py-0.5 gap-x-1',
	{
		variants: {
			variant: {
				default:
					'border-transparent bg-gradient-primary text-primary-foreground hover:bg-gradient-primary/80',
				low: 'border-transparent bg-wallets-lowbg text-wallets-lowtext hover:bg-secondary/80',
				critical:
					'border-transparent bg-wallets-criticalbg text-wallets-criticaltext hover:bg-gradient-critical/80',
				medium:
					'border-transparent bg-wallets-mediumbg text-wallets-mediumtext hover:bg-gradient-medium/80',
				high: 'border-transparent bg-wallets-highbg text-wallets-hightext hover:bg-gradient-high/80',
				outline: 'text-foreground',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	}
);

export interface BadgeProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof badgeVariants> {
	hideIcon?: boolean;
}

function Badge({ className, variant, hideIcon, ...props }: BadgeProps) {
	return (
		<div
			className={cn(
				'w-fit capitalize min-w-[80px] justify-center font-bold',
				badgeVariants({ variant }),
				className
			)}
			{...props}
		>
			{props.children}
			{variant === 'critical' && !hideIcon && (
				<ResponsiveIcon icon={iconMap.warn} />
			)}
		</div>
	);
}

export { Badge, badgeVariants };
